// File automatically generated via terraform, values are set in vault.prod-apsmos.com

export const environment = {
  production: true,
  baseUrl: 'https://demo-portal-cms.prod-apsmos.com',
  logout: 'https://demo-portal-cms.prod-apsmos.com/logout',
  graphqlUri: 'https://demo-portal-cms.prod-apsmos.com/graphql',
  keyCloakLogin: 'https://demo-portal-cms.prod-apsmos.com/connect/keycloak',
  strapiJwtEndpoint: 'https://demo-portal-cms.prod-apsmos.com/auth/keycloak/callback',
  cannyTokenEndpoint: 'https://demo-portal-cms.prod-apsmos.com/canny',
  sentryDSN: 'https://5351f10531524a26928357152d47b639@o411851.ingest.sentry.io/5638911',
  ssoLink: 'sso',
  useFreshStatusWidget: 'false',
  siteTitle: 'Demo Portal'
};
